import Button from "../atoms/button";
import { partnerStatusObject } from "../utils/allProductStatus";
import { formatarData } from "../utils/functions";

const PartnerProductsList = ({ products, setEdit, setEditableProduct }) => {

    const handleEdit = (product) => {
        setEdit(true);
        setEditableProduct(product);
    }

    return (
        <table className="text-center m-auto gap-5">
            <thead>
                <tr>
                    <th className="p-4">Nome Cliente</th>
                    <th className="p-4">Email Cliente</th>
                    <th className="p-4">Nº Cartas</th>
                    <th className="p-4">Criado</th>
                    <th className="p-4">Status</th>
                    <th className="p-4"></th>
                </tr>
            </thead>
            <tbody>
                {
                    products.map((product, idx) => (
                        <tr key={idx}>
                            <td className="p-4">{product.user.name}</td>
                            <td className="p-4">{product.user.email}</td>
                            <td className="p-4">{product.numberOfCards}</td>
                            <td className="p-4">{formatarData(product.createdAt)}</td>
                            <td className="p-4">{partnerStatusObject[product.status]}</td>
                            <td className="p-4"><Button classes="bg-blue-500 hover:bg-blue-800" click={() => handleEdit(product)}>Atualizar</Button></td>
                        </tr>
                        
                    ))
                }
            </tbody>
        </table>
    )
}

export default PartnerProductsList;