import { useContext, useState } from "react";
import Button from "../atoms/button";
import { getCoupons } from "./functions";
import ToastContext from "../../context/toastProvider";
import Loading from "../atoms/loading";
import CouponsList from "./couponsList";
import CreateModal from "./createModal";
import CouponModal from "./couponModal";

const Coupons = ({ user }) => {

    const { badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("");
    const [coupons, setCoupons] = useState([]);
    const [editableCoupon, setEditableCoupon] = useState(null);
    const [edit, setEdit] = useState(false);
    const [create, setCreate] = useState(false);

    const handleSearch = async () => {
        setLoading(true);
        const data = await getCoupons(user.token, code);
        if(data.type === "error"){
            badToast("Erro ao buscar cupons!");
        }
        setCoupons(data);
        setLoading(false);
    }

    return (
        <main className="text-center">
            <h1>Cupons</h1>
            <div className="border-b border-t border-[#d59106] p-2">
                <input type="text" value={code} onChange={e => setCode(e.target.value)} placeholder="Código" className="text-center text-black"/>
                <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline mx-4">{code === "" ? "Pesquisar todos" : "Pesquisar"}</Button>
                <Button classes="bg-blue-300 hover:bg-blue-400 inline" click={() => setCreate(true)}>Cadastrar Cupom</Button>
            </div>
            {
                loading ? <Loading classes="m-auto"/>
                : coupons?.type === "success" ?
                <CouponsList coupons={coupons.data} setEdit={setEdit} setEditableCoupon={setEditableCoupon}/>
                : coupons?.type === "error" ?
                <div>
                    <p className="text-center m-8 text-red-600">{coupons.message}</p>
                </div>
                : null
            }
            {
                create ? <CreateModal setCreate={setCreate} refresh={handleSearch}/> : null
            }
            {
                edit ? <CouponModal setEdit={setEdit} editableCoupon={editableCoupon} refresh={handleSearch}/> : null
            }

        </main>
    )
}

export default Coupons;
