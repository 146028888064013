export const statusList = ["CART", "GENERATED", "PIX", "PROCESSED", "PENDING", "APPROVED", "CANCELED", "HOLD", "SENT", "QUEUE", "GRADING", "GRADED", "AWAITING", "RETURNED", "DELIVERED", "DONE"];

export const statusObject = {
    "CART": "CARRINHO",
    "GENERATED": "CRIADO PELO ADMIN",
    "PIX": "PIX GERADO",
    "PROCESSED": "PAGAMENTO PROCESSADO",
    "PENDING": "PAGAMENTO PENDENTE",
    "APPROVED": "PAGAMENTO AUTORIZADO",
    "CANCELED": "PAGAMENTO CANCELADO",
    "HOLD": "AGUARDANDO MANAFIX",
    "SENT": "ENVIADO PARA MANAFIX",
    "QUEUE": "RECEBIDO",
    "GRADING": "AVALIANDO",
    "GRADED": "AVALIADO",
    "AWAITING": "AGUARDANDO CLIENTE",
    "RETURNED": "ENVIADO PARA CLIENTE",
    "DELIVERED": "ENTREGUE",
    "DONE": "FINALIZADO"
}

export const partnerStatusList = ["GENERATED", "APPROVED", "HOLD", "AWAITING", "DELIVERED"];

export const partnerStatusObject = {
    "GENERATED": "CRIADO PELO ADMIN",
    "APPROVED": "PAGAMENTO AUTORIZADO",
    "HOLD": "AGUARDANDO MANAFIX",
    "QUEUE": "RECEBIDO PELA MANAFIX",
    "AWAITING": "AGUARDANDO CLIENTE",
    "DELIVERED": "ENTREGUE"
}
