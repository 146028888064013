import { useState } from "react";
import { useContext } from "react";
import AuthContext from "../context/authProvider";
import { useEffect } from "react";
import Button from "./atoms/button";

const Login = ({ user, navigate }) => {

    const { login, status, message } = useContext(AuthContext);

    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const [emailColor, setEmailColor] = useState("rgb(252, 230, 218)");
    const [passColor, setPassColor] = useState("rgb(252, 230, 218)");

    useEffect(() => {
        if(user){
            if(user.roles.includes("admin")) navigate("/manafix");
            else navigate("/partner");
        }
        // eslint-disable-next-line
    }, [user, status])

    const handleSubmit = (e) => {
        e.preventDefault();

        if(email === ""){
            setEmailColor("red");
            return;
        }
        setEmailColor("rgb(252, 230, 218)");
        if(pass === ""){
            setPassColor("red");
            return
        }
        setPassColor("rgb(252, 230, 218)");

        const body = {
            email: email,
            password: pass
        }
        login(body);
    }


    return (
        !user ?
        <main className="mt-20">
            <h1 className="text-[#d59106] text-center">MGS ADMIN</h1>
            <form className="w-fit m-auto" onSubmit={handleSubmit}>
                <div className="border border-[#d59106] border-b-0 flex w-full p-5">
                    <label className="w-20 text-center pr-1" htmlFor="email" style={{color: emailColor}}>Email: </label>
                    <input className="" type="text" id="email" value={email} onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className="border border-[#d59106] border-t-0 flex w-full p-5 mb-4">
                    <label className="w-20 text-center pr-1" htmlFor="pass" style={{color: passColor}}>Senha: </label>
                    <input className="" type="password" id="pass" value={pass} onChange={e => setPass(e.target.value)}/>
                </div>
                {/* <button className="bg-[#d59106] m-auto block hover:bg-[#b47c06]" type="submit">Entrar</button> */}
                <Button type="submit" classes="bg-[#d59106] hover:bg-[#b47c06]">Entrar</Button>
            </form>
            {status === "error"? <p className="text-red-600 text-center m-4">{message}</p> : null}
            
        </main>
        : null
    )
}

export default Login;