const Loading = ({ classes=null }) => {
    return (
        <div className={`text-[#d59106] flex w-fit ${classes}`}>
            <p>Carregando</p>
            <p className="animate-blink1">.</p>
            <p className="animate-blink2">.</p>
            <p className="animate-blink3">.</p>
        </div>
    )
}

export default Loading;