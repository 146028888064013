import { useContext, useState } from "react";
import Button from "../atoms/button";
import Modal from "../utils/modal"
import { addCoupon } from "./functions";
import AuthContext from "../../context/authProvider";
import ToastContext from "../../context/toastProvider";

const CreateModal = ({ setCreate, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [code, setCode] = useState("");
    const [description, setDescription] = useState("");
    const [value, setValue] = useState("");
    const [kind, setKind] = useState("");
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setCreate(false);
    }

    const handleSubmit = async () => {
        if(code === ""){
            return badToast("Insira um código!");
        }
        if(description === ""){
            return badToast("Insira uma descrição!");
        }
        if(value === ""){
            return badToast("Insira um valor!");
        }
        if(kind === ""){
            return badToast("Determine o tipo!");
        }
        setLoading(true);
        const body = {
            code,
            description,
            value,
            kind
        }
        const data = await addCoupon(user.token, body);
        if(data.type === "success"){
            setLoading(false);
            goodToast("Novo cupom cadastrado!");
            refresh();
            return handleClose();
        }
        setLoading(false);
        return badToast("Erro ao cadastrar cupom");
    }

    return (
        <Modal handleClose={handleClose}>
            <h2>Cadastro de Cupom</h2>
            <div className="grid grid-cols-2 w-fit m-auto gap-3 mt-10">
                <label>Código: </label>
                <input type="text" value={code} onChange={e => setCode(e.target.value)} className="text-black text-center"/>

                <label>Descrição: </label>
                <input type="text" value={description} onChange={e => setDescription(e.target.value)} className="text-black text-center"/>

                <label>Valor: </label>
                <input type="text" value={value} onChange={e => setValue(e.target.value)} className="text-black text-center"/>

                <label>
                Percentual <input type="radio" name="kind" value="percent" onChange={e => setKind(e.target.value)}/>
                </label>
                <label>
                Quantia <input type="radio" name="kind" value="amount" onChange={e => setKind(e.target.value)}/>
                </label>
            </div>
            <div className="flex w-1/2 m-auto mt-4">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit} disabled={loading}>Cadastrar</Button>
            </div>
        </Modal>
    )
}

export default CreateModal;
