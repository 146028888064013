import { useContext, useEffect, useState } from "react";
import Button from "../atoms/button";
import Modal from "../utils/modal";
import Loading from "../atoms/loading";
import { cashnator, decashnator, isNumeric } from "../utils/functions";
import { addProduct, getActiveStores } from "./functions";
import ToastContext from "../../context/toastProvider";

const CreateProductModal = ({ user, setEdit, refresh, partner=false }) => {

    const { goodToast, badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [gradingType, setGradingType] = useState("");
    const [numberOfCards, setNumberOfCards] = useState("");
    const [delivery, setDelivery] = useState("");
    const [priority, setPriority] = useState("none");
    const [declaredValue, setDeclaredValue] = useState(0);
    const [insurance, setInsurance] = useState("");
    const [stores, setStores] = useState([]);
    const [store, setStore] = useState("");

    const gradingTypes = [{text: "Manafix RAW", value: "raw"}, {text: "Manafix STANDARD", value: "standard"}, {text: "Manafix PREMIUM", value: "premium"}, {text: "Manafix PREMIUM - PRÉ GRADING", value: "pre"}];
    const deliveryTypes = [{text: "Presencial", value: "presencial"}, {text: "Pelos Correios", value: "correios"}];
    const priorityTypes = [
        {text: "Sem Prioridade", value: "none"}, {text: "Prioridade Baixa", value: "low"},
        {text: "Prioridade Média", value: "mid"}, {text: "Prioridade Alta", value: "high"},
        {text: "Prioridade Máxima", value: "max"}
    ]

    useEffect(() => {
        doGetStores();
        // eslint-disable-next-line
    }, [])

    const doGetStores = async () => {
        if(!stores.length){
            const data = await getActiveStores();
            if(data.type === "success"){
                setStores(data.data);
                return null;
            }
            badToast(data.message);
        }
    }

    const handleClose = () => {
        setEdit(false);
    }

    const handleSubmit = async () => {
        setLoading(true);
        let body = {
            userEmail,
            gradingType,
            numberOfCards,
            delivery,
            priority,
            insurance: 5/100*declaredValue,
        }
        if(delivery === "presencial"){
            body.store = store;
        }
        const data = await addProduct(user.token, body, partner);
        setLoading(false);
        if(data.type === "success"){
            goodToast("Pedido registrado!");
            handleClose();
            refresh();
            return null;
        }
        badToast(data.message);
    }

    const handleNumberOfCards = (value) => {
        if(isNumeric(value)){
            setNumberOfCards(value);
        }
    }

    const handleDeclaredValue = (value) => {
        const newValue = decashnator(value)
        if(isNumeric(newValue)){
            setDeclaredValue(newValue);
            setInsurance(`R$${(newValue*5/10000).toFixed(2).toString().replace(".", ",")}`);
        }
    }

    return (
        <Modal handleClose={handleClose}>
            <h2>Criação de Pedido</h2>
            <div className="grid grid-cols-2 w-fit m-auto gap-3 mt-10">

                <label>Email do Usuário: </label>
                <input type="text" value={userEmail} onChange={e => setUserEmail(e.target.value)} className="text-black text-center"/>

                <label>Grading Type: </label>
                <select value={gradingType} onChange={e => setGradingType(e.target.value)} className="text-black text-center">
                    <option value={""}>Selecionar</option>
                    {
                        gradingTypes.map((gt, idx) => (
                            <option key={idx} value={gt.value}>{gt.text}</option>
                        ))
                    }
                </select>

                <label>Número de cartas: </label>
                <input type="text" value={numberOfCards} onChange={e => handleNumberOfCards(e.target.value)} className="text-black text-center"/>

                <label>Entrega: </label>
                <select value={delivery} onChange={e => setDelivery(e.target.value)} className="text-black text-center">
                    <option value={""}>Selecionar</option>
                    {
                        deliveryTypes.map((dt, idx) => (
                            <option key={idx} value={dt.value}>{dt.text}</option>
                        ))
                    }
                </select>

                <label>Prioridade: </label>
                <select value={priority} onChange={e => setPriority(e.target.value)} className="text-black text-center">
                    {
                        priorityTypes.map((pt, idx) => (
                            <option key={idx} value={pt.value}>{pt.text}</option>
                        ))
                    }
                </select>

                <label>Valor dos itens: </label>
                <input type="text" value={cashnator(declaredValue)} onChange={e => handleDeclaredValue(e.target.value)} className="text-black text-center"/>

                <label>Seguro: </label>
                <label>{insurance}</label>

                {
                    delivery === "presencial" ?
                        <label>Loja: </label>
                    : null
                }
                {
                    delivery === "presencial" ?
                        <select value={store} onChange={e => setStore(e.target.value)} className="text-black text-center">
                            <option value={""}>Selecionar</option>
                            {
                                stores.map((s, idx) => (
                                    <option key={idx} value={s._id}>{`${s.name} - ${s.city}`}</option>
                                ))
                            }
                        </select>
                    : null
                }
            </div>
            <div className="flex w-1/2 m-auto mt-10">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit} disabled={loading}>Criar</Button>
            </div>
            {loading ? <Loading classes="m-auto my-2"/> : null}
        </Modal>
    )
}

export default CreateProductModal;
