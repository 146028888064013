import { useContext, useState } from "react";
import Button from "../../atoms/button";
import AuthContext from "../../../context/authProvider";
import ToastContext from "../../../context/toastProvider";
import { updateGradableModel } from "../functions";

const GradableModelInfos = ({ handleClose, editableGradableModel, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [game, setGame] = useState(editableGradableModel.game);
    const [name, setName] = useState(editableGradableModel.name);
    const [edition, setEdition] = useState(editableGradableModel.edition);
    const [year, setYear] = useState(editableGradableModel.year);
    const [language, setLanguage] = useState(editableGradableModel.language);
    const [numbering, setNumbering] = useState(editableGradableModel.numbering);
    const [rarity, setRarity] = useState(editableGradableModel.rarity);
    const [serialInitial, setSerialInitial] = useState(editableGradableModel.serialInitial);
    const [enableChanges, setEnableChanges] = useState(false);

    const handleChanges = () => {
        let changes = {};
        if(game !== editableGradableModel.game){
            changes.game = game;
        }
        if(name !== editableGradableModel.name){
            changes.name = name;
        }
        if(edition !== editableGradableModel.edition){
            changes.edition = edition;
        }
        if(year !== editableGradableModel.year){
            changes.year = year;
        }
        if(language !== editableGradableModel.language){
            changes.language = language;
        }
        if(numbering !== editableGradableModel.numbering){
            changes.numbering = numbering;
        }
        if(rarity !== editableGradableModel.rarity){
            changes.rarity = rarity;
        }
        if(serialInitial !== editableGradableModel.serialInitial){
            changes.serialInitial = serialInitial;
        }

        return changes;
    }

    const handleSubmit = async () => {
        const body = handleChanges();
        const data = await updateGradableModel(user.token, editableGradableModel._id, body);
        if(data.type === "success"){
            goodToast("Modelo avaliável atualizado");
            handleClose();
            refresh();
        }
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    return(
        <div>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableGradableModel._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            <div className="grid grid-cols-2 gap-2 w-fit m-auto">
                <label>Jogo: </label>
                <input type="text" value={game} onChange={e => setGame(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Nome: </label>
                <input type="text" value={name} onChange={e => setName(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Edição: </label>
                <input type="text" value={edition} onChange={e => setEdition(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Ano: </label>
                <input type="text" value={year} onChange={e => setYear(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Idioma: </label>
                <input type="text" value={language} onChange={e => setLanguage(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Numeração: </label>
                <input type="text" value={numbering} onChange={e => setNumbering(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Raridade: </label>
                <input type="text" value={rarity} onChange={e => setRarity(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Inicial Serial: </label>
                <input type="text" value={serialInitial} onChange={e => setSerialInitial(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
            </div>
            <Button classes="bg-[#d59106] hover:bg-orange-500 my-4" click={() => setEnableChanges(!enableChanges)}>{enableChanges ? "Bloquear alterações" : "Liberar alterações"}</Button>
            <div className="flex w-1/2 mx-auto my-4">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit} disabled={!enableChanges}>Atualizar</Button>
            </div>
        </div>
    )
}

export default GradableModelInfos;
