import { useState } from "react";
import Modal from "../../utils/modal"
import ProductStatus from "./productStatus";
import ProductInfos from "./productInfos";

const ProductModal = ({ setEdit, editableProduct, refresh }) => {

    const [tab, setTab] = useState(0);

    const handleClose = () => {
        setEdit(false);
    }

    const activeTab = "bg-[#d59106] text-black px-2 cursor-pointer";
    const inactiveTab = "border border-[#d59106] px-2 cursor-pointer";
    // const disabledTab = "bg-gray-700 text-black px-2";

    return (
        <Modal handleClose={handleClose}>
            <div className="text-left h-8 flex gap-2"  onClick={(e) => e.stopPropagation()}>
                <span className={tab === 0 ? activeTab : inactiveTab} onClick={() => setTab(0)}>
                    Status
                </span>
                <span className={tab === 1 ? activeTab : inactiveTab} onClick={() => setTab(1)}>
                    Infos
                </span>
            </div>
            {
                tab === 0 ? <ProductStatus editableProduct={editableProduct} handleClose={handleClose} refresh={refresh}/>
                : tab === 1 ? <ProductInfos editableProduct={editableProduct}/> : null
            }

        </Modal>
    )
}

export default ProductModal;