import { SIGNUP_URL, USERS_URL } from "../../context/paths";

export const getUsers = async (token, email, admin, partner) => {

    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    let queryUrl = USERS_URL;
    const params = new URLSearchParams();
    if(email){
        params.append("user_email", email);
    }
    if(admin){
        params.append("role", "admin");
    }
    if(partner){
        params.append("role", "partner");
    }
    if(params){
        queryUrl += "?"+params.toString();
    }

    try{
        const response = await fetch(queryUrl, searchOptions);
        return await response.json();
    }
    catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const updateUserInfo = async (token, userId, body) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const updateUrl = `${USERS_URL}/info/${userId}`;
    try{
        const response = await fetch(updateUrl, updateOptions);
        return await response.json();
    }
    catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const updateUserPass = async (token, userId, body) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const updateUrl = `${USERS_URL}/pass/${userId}`;
    try{
        const response = await fetch(updateUrl, updateOptions);
        return await response.json();
    }
    catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const assignStoreToUser = async (token, userId, body) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const updateUrl = `${USERS_URL}/store/${userId}`;
    try{
        const response = await fetch(updateUrl, updateOptions);
        return await response.json();
    }
    catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const signup = async (token, body) => {
    const singupOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }
    try{
        const response = await fetch(SIGNUP_URL, singupOptions);
    return await response.json();
    }
    catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}
