import { COUPONS_URL } from "../../context/paths"

export const getCoupons = async (token, code=null) => {
    try{
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }

        let getURL = COUPONS_URL;
        if(code){
            getURL+="?code="+code.toUpperCase();
        }

        const response = await fetch(getURL, getOptions);
        return await response.json();
        
    }catch(error){
        return {
            message: "Erro ao obter cupons!",
            type: "error"
        }
    }
}

export const addCoupon = async (token, body) => {
    try{
        const addOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        }

        const addURL = `${COUPONS_URL}/add`;
        const response = await fetch(addURL, addOptions);
        return await response.json();

    }catch(error){
        return {
            message: "Erro ao cadastrar cupom!",
            type: "error"
        }
    }
}

export const updateCoupon = async (token, couponId, body) => {
    try{
        const updateOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        }

        const updateURL = `${COUPONS_URL}/update/${couponId}`;
        const response = await fetch(updateURL, updateOptions);
        return await response.json();

    }catch(error){
        return {
            message: "Erro ao atualizar cupom!",
            type: "error"
        }
    }
}
