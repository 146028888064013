import { useContext, useState } from "react";
import Button from "../atoms/button";
import { getUsers } from "./functions";
import Loading from "../atoms/loading";
import UsersList from "./usersList";
import UserModal from "./userModal/userModal";
import ToastContext from "../../context/toastProvider";
import RegisterModal from "./registerModal";

const Users = ({ user }) => {

    const { badToast } = useContext(ToastContext);

    const [search, setSearch] = useState("");
    const [users, setUsers] = useState([]);
    const [admin, setAdmin] = useState(false);
    const [partner, setPartner] = useState(false);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editableUser, setEditableUser] = useState(null);
    const [register, setRegister] = useState(false);

    const searchUsers = async () => {
        const data = await getUsers(user.token, search, admin, partner);
        setUsers(data);
        setLoading(false)
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    const handleSearch = () => {
        setLoading(true);
        searchUsers();
    }

    return (
        <main className="text-center">
            <h1>Usuários</h1>
            <div className="border-b border-t border-[#d59106] p-2 flex">
                <div className="flex-1">
                    <input className="m-2 text-black" type="text" placeholder="Email do usuário" value={search} onChange={e => setSearch(e.target.value)}/>
                    <label className="m-2" htmlFor="admins">Admin</label>
                    <input className="m-2" type="checkbox" id="admins" checked={admin} onChange={() => setAdmin(!admin)}/>
                    <label className="m-2" htmlFor="partners">Parceiro</label>
                    <input className="m-2" type="checkbox" id="partners" checked={partner} onChange={() => setPartner(!partner)}/>
                    <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline ml-4">{search === "" ? "Pesquisar todos" : "Pesquisar"}</Button>
                </div>
                <Button classes="bg-blue-300 hover:bg-blue-400" click={() => setRegister(true)}>Cadastrar Usuário</Button>
            </div>
            
            {
                loading ? <Loading classes="m-auto"/>
                : users?.type === "success" ?
                <UsersList users={users.data} setEdit={setEdit} setEditableUser={setEditableUser}/>
                : users?.type === "error" ?
                <p className="text-center m-8 text-red-600">{users.message}</p>
                : null
            }
            {
                edit ? <UserModal setEdit={setEdit} editableUser={editableUser} refresh={handleSearch}/> : null
            }
            {
                register ? <RegisterModal setRegister={setRegister}/> : null
            }
            
        </main>
    )
}

export default Users;