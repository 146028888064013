import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/authProvider";
import ToastContext from "../../../context/toastProvider";
import { getSerialsInfos } from "../functions";
import Loading from "../../atoms/loading";
import { formatarData } from "../../utils/functions";
import Button from "../../atoms/button";

const GradableModelSerials = ({ editableGradableModel, handleClose, refresh }) => {

    const { user } = useContext(AuthContext);
    const { badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [serialsData, setSerialsData] = useState({});

    const fetchData = async () => {
        setLoading(true);
        const data = await getSerialsInfos(user.token, editableGradableModel._id);
        if(data.type === "error"){
            badToast(data.message);
        }
        setSerialsData(data);
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    return (
        <div className="my-4">
            {
                loading ?
                <Loading classes="m-auto"/>
                : serialsData.type === "success" ?
                <div className="grid grid-cols-2 w-fit m-auto gap-3">
                    <p>Nº de seriais:</p>
                    <p>{serialsData.data.count}</p>
                    <p>Maior nota:</p>
                    <p><span className="border border-green-600 p-1">{serialsData.data.max.generalScore}</span> {serialsData.data.max.serialNumber}</p>
                    <p>Menor nota:</p>
                    <p><span className="border border-red-600 p-1">{serialsData.data.min.generalScore}</span> {serialsData.data.min.serialNumber}</p>
                    <p>Último cadastro:</p>
                    <p>{serialsData.data.last.serialNumber} em {formatarData(serialsData.data.last.createdAt)}</p>
                </div>
                : "Nenhum serial encontrado para esse modelo"
            }
            <div className="flex w-1/2 mx-auto my-4">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Fechar</Button>
            </div>
        </div>
    )
}

export default GradableModelSerials;
