import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAuth = ({ user, allowedRoles }) => {
    const location = useLocation();

    return (

        user && user.verified && user.roles.find(role => allowedRoles.includes(role))
        ? <Outlet/>
        : !user ? <Navigate to={"/"} state={{from: location}}/> 
        : <Navigate to={"/youshallnotpass"} state={{from: location}}/>

    )
}

export default RequireAuth;