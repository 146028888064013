import { useState } from "react";
import Button from "../atoms/button";

const TransactionsList = ({ transactions, setEdit, setEditableTransaction }) => {

    const [page, setPage] = useState(1);

    const gmsLen = transactions.length;
    const perPage = 100;
    let gmsPages;
    if(gmsLen % perPage === 0){
        gmsPages = Math.floor(gmsLen / perPage) || 1;
    }else{
        gmsPages = Math.floor(gmsLen / perPage)+1 || 1;
    }

    const paginatedTransactions = transactions.slice((page-1)*perPage, page*perPage);

    const nextPage = () => {
        if(page<gmsPages){
            setPage(page+1);
        }
    }

    const previousPage = () => {
        if(page>1){
            setPage(page-1);
        }
    }

    const handleEdit = (transaction) => {
        setEdit(true);
        setEditableTransaction(transaction);
    }

    return (
        <div>
            <table className="text-center m-auto gap-5">
                <thead>
                    <tr>
                        <th className="p-4">ID Produto</th>
                        <th className="p-4">Preço</th>
                        <th className="p-4">Usuário</th>
                        <th className="p-4">Status</th>
                        <th className="p-4"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        paginatedTransactions.map((transaction, idx) => (
                            <tr key={idx}>
                                <td className="p-4">{transaction?.product?._id}</td>
                                <td className="p-4">{"R$"+(transaction?.product?.finalPrice/100).toFixed(2).replace(".", ",")}</td>
                                <td className="p-4">{transaction?.user?.email}</td>
                                <td className="p-4">{transaction?.data?.status}</td>
                                <td className="p-4"><Button classes="bg-blue-500 hover:bg-blue-800" click={() => handleEdit(transaction)}>Info</Button></td>
                            </tr>
            
                        ))
                    }
                </tbody>
            </table>
            <div className="my-4">
                <label>Página </label>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={previousPage} disabled={page===1}>
                    {"< "}
                </Button>
                <h3 className="inline mx-4">{page}</h3>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={nextPage} disabled={page===gmsPages}>
                    {" >"}
                </Button>
                <h3 className="inline mx-4"> de {gmsPages}</h3>
            </div>
        </div>
    )
}

export default TransactionsList;