import { HOME_URL } from "../../context/paths"

export const getGeneralInfo = async (token) => {
    try{
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }

        const response = await fetch(HOME_URL, getOptions);
        return await response.json();
    }catch(error){
        return {
            message: "Something went wrong",
            type: "error",
            error: error.message
        }
    }
}

export const getPartnerGeneralInfo = async (token) => {
    try{
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }

        const response = await fetch(HOME_URL+"/partner", getOptions);
        return await response.json();
    }catch(error){
        return {
            message: "Something went wrong",
            type: "error",
            error: error.message
        }
    }
}
