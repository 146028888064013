import { cashnator, formatarData } from "../../utils/functions";

const ProductInfos = ({ editableProduct }) => {

    const paidAt = editableProduct.paidAt ? formatarData(new Date(editableProduct.paidAt)) : "-";
    const holdAt = editableProduct.holdAt ? formatarData(new Date(editableProduct.holdAt)) : "-";
    const receivedAt = editableProduct.receivedAt ? formatarData(new Date(editableProduct.receivedAt)) : "-";
    const gradedAt = editableProduct.gradedAt ? formatarData(new Date(editableProduct.gradedAt)) : "-";

    return(
        <div>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableProduct._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            <button onClick={() => console.log(editableProduct)}>depurate</button>
            <p className="my-4">Usuário: {editableProduct?.user?.name}</p>
            <p className="my-4">Email: {editableProduct?.user?.email}</p>
            <p className="my-4">Tipo da avaliação: MANAFIX {editableProduct?.gradingType === "pre" ? "PRÉ GRADING" : editableProduct?.gradingType?.toUpperCase()}</p>
            <p className="my-4">Nº Cartas: {editableProduct?.numberOfCards}</p>
            <p className="my-4">Prioridade: {editableProduct?.priority}</p>
            <p className="my-4">Prazo: {editableProduct?.deadline} dias</p>
            <p className="my-4">Entrega: {editableProduct?.delivery}</p>
            <p className="my-4">Preço: {cashnator(editableProduct?.finalPrice)}</p>
            {
                editableProduct?.delivery === "correios" ? 
                <p className="my-4">Código de rastreio: {editableProduct?.trackingCode || "não informado"}</p>
                : null
            }
            {
                editableProduct.delivery === "presencial" ? 
                <p className="my-4">Loja: {editableProduct.store?.name}</p>
                : null
            }
            <div className="">
                <div className="w-1/2 flex m-auto">Pago: <span className="text-right flex-1">{paidAt}</span></div>
                {
                    editableProduct.delivery === "presencial" && editableProduct.store._id.toString() !== "651ea71ab7f1091f37f9230d" ?
                    <div className="w-1/2 flex m-auto">Recebido pelo parceiro: <span className="text-right flex-1">{holdAt}</span></div>
                    : null
                }
                
                <div className="w-1/2 flex m-auto">Recebido: <span className="text-right flex-1">{receivedAt}</span></div>
                <div className="w-1/2 flex m-auto">Avaliado: <span className="text-right flex-1">{gradedAt}</span></div>
            </div>
            
        </div>
    )
}

export default ProductInfos;