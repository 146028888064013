import Loading from "../atoms/loading";

const LoadingScreen = () => {
    return (
        <main className="bg-black h-screen p-40">
            <Loading classes="m-auto text-xl"/>
        </main>
    )
}

export default LoadingScreen;