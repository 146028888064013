import { useContext, useState } from "react";
import Modal from "../utils/modal"
import Button from "../atoms/button";
import { updateStore } from "./functions";
import AuthContext from "../../context/authProvider";
import ToastContext from "../../context/toastProvider";

const StoreModal = ({ setEdit, editableStore, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [name, setName] = useState(editableStore.name);
    const [city, setCity] = useState(editableStore.city);
    const [state, setState] = useState(editableStore.state);
    const [address, setAddress] = useState(editableStore.address);
    const [active, setActive] = useState(editableStore.active);
    const [headquarter, setHeadquarter] = useState(editableStore.headquarter);
    
    const handleClose = () => {
        setEdit(false)
    }

    const handleChanges = () => {
        let changes = {};
        if(name !== editableStore.name){
            changes.name = name;
        }
        if(city !== editableStore.city){
            changes.city = city;
        }
        if(state !== editableStore.state){
            changes.state = state;
        }
        if(address !== editableStore.address){
            changes.address = address;
        }
        if(active !== editableStore.active){
            changes.active = active;
        }
        if(headquarter !== editableStore.headquarter){
            changes.headquarter = headquarter;
        }

        return changes;
    }


    const handleSubmit = async () => {
        const body = handleChanges();
        const data = await updateStore(user.token, editableStore._id, body);
        if(data.type === "success"){
            goodToast("Loja atualizada");
            handleClose();
            refresh();
        }
        if(data.type === "error"){
            badToast(data.message);
        }
    }
    
    return (
        <Modal handleClose={handleClose}>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableStore._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            <div>
                <label>Headquarter: </label>
                <input type="checkbox" checked={headquarter} onChange={e => setHeadquarter(!headquarter)} className="text-black text-center"/>
            </div>
            <div className="my-4">
                <label>Nome: </label>
                <input type="text" value={name} onChange={e => setName(e.target.value)} disabled={editableStore._id.toString() === "651ea71ab7f1091f37f9230d"} className="text-black text-center mr-2 w-1/2"/>
                <label>Ativa: </label>
                <input type="checkbox" checked={active} onChange={e => setActive(!active)} className="text-black text-center"/>
            </div>
            <div className="grid grid-cols-[1fr,2fr] mx-10 my-4 gap-y-2">
                <label>Cidade: </label>
                <input type="text" value={city} onChange={e => setCity(e.target.value)} className="text-black text-center"/>
                <label>Estado: </label>
                <input type="text" value={state} onChange={e => setState(e.target.value)} className="text-black text-center"/>
                <label>Endereço: </label>
                <input type="text" value={address} onChange={e => setAddress(e.target.value)} className="text-black text-center"/>
            </div>
            <div className="flex w-1/2 m-auto mt-10">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit}>Atualizar</Button>
            </div>
        </Modal>
    )
}

export default StoreModal;