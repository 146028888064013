import { useState } from "react";
import Button from "../atoms/button";
import { formatarData, pegarHorario } from "../utils/functions";

const LogsList = ({ logs, setEdit, setEditableLog }) => {

    const [page, setPage] = useState(1);

    const logsLen = logs.length;
    const perPage = 100;
    let logsPages;
    if(logsLen % perPage === 0){
        logsPages = Math.floor(logsLen / perPage) || 1;
    }else{
        logsPages = Math.floor(logsLen / perPage)+1 || 1;
    }

    const paginatedLogs = logs.slice((page-1)*perPage, page*perPage);

    const handleEdit = (log) => {
        setEdit(true);
        setEditableLog(log);
    }

    const nextPage = () => {
        if(page<logsPages){
            setPage(page+1);
        }
    }

    const previousPage = () => {
        if(page>1){
            setPage(page-1);
        }
    }

    const targetNames = {
        user: "Usuário",
        product: "Produto",
        store: "Loja",
        gradablemodel: "Modelo",
        serial: "Serial",
        coupon: "Cupom"
    }

    return (
        <div>
            <table className="text-center m-auto gap-5">
                <thead>
                    <tr>
                        <th className="p-4">Alvo</th>
                        <th className="p-4">ID alvo</th>
                        <th className="p-4">Usuário</th>
                        <th className="p-4">Data</th>
                        <th className="p-4">Horário</th>
                        <th className="p-4"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        paginatedLogs.map((log, idx) => (
                            <tr key={idx}>
                                <td className="p-4">{targetNames[log.target]}</td>
                                <td className="p-4">{log.targetId}</td>
                                <td className="p-4">{log.user.email}</td>
                                <td className="p-4">{formatarData(log.createdAt) || "-"}</td>
                                <td className="p-4">{pegarHorario(log.createdAt) || "-"}</td>
                                <td className="p-4"><Button classes="bg-blue-500 hover:bg-blue-800" click={() => handleEdit(log)}>Info</Button></td>
                            </tr>
                            
                        ))
                    }
                </tbody>
            </table>
            
            <div className="my-4">
                <label>Página </label>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={previousPage} disabled={page===1}>
                    {"< "}
                </Button>
                <h3 className="inline mx-4">{page}</h3>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={nextPage} disabled={page===logsPages}>
                    {" >"}
                </Button>
                <h3 className="inline mx-4"> de {logsPages}</h3>
            </div>
        </div>
    )
}

export default LogsList;
