import { useContext, useState } from "react";
import Modal from "../utils/modal";
import Button from "../atoms/button";
import ToastContext from "../../context/toastProvider";
import { signup } from "./functions";
import Loading from "../atoms/loading";
import AuthContext from "../../context/authProvider";

const RegisterModal = ({ setRegister }) => {

    const { goodToast, badToast } = useContext(ToastContext);
    const { user } = useContext(AuthContext);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [birth, setBirth] = useState("");
    const [pass, setPass] = useState("");
    const [phone, setPhone] = useState("");
    const [docNumber, setDocNumber] = useState("");
    const [loading, setLoading] = useState(false);

    const setFormattedBirth = (value) => {
        value = value.replace(/\D/g, '');
        if(value.length > 2){
            value = value.substring(0, 2) + '/' + value.substring(2);
        }
        if(value.length > 5){
            value = value.substring(0, 5) + '/' + value.substring(5);
        }
        if(value.length <= 10){
            setBirth(value);
        }
    }

    const handleClose = () => {
        setRegister(false)
    }

    const handleSubmit = async () => {
        if(name === ""){
            return badToast("Insira um nome");
        }
        if(email === "" || !email.includes("@") || !email.includes(".")){
            return badToast("Email inválido!");
        }
        if(birth.length !== 10){
            return badToast("Data de nascimento inválida!");
        }
        if(pass.length < 6){
            return badToast("Senha muito curta!");
        }
        if(phone.length < 8){
            return badToast("Telefone inválido");
        }
        if(docNumber.length < 11){
            return badToast("CPF inválido");
        }
        
        
        setLoading(true);

        const day = birth.split("/")[0]
        const month = birth.split("/")[1]-1
        const year = birth.split("/")[2]
        const birthDate = new Date(year, month, day);
        
        const registerBody = {
            name,
            birth: birthDate,
            email,
            password: pass,
            phone,
            documentNumber: docNumber
        }

        const data = await signup(user.token, registerBody);
        if(data.type === "success"){
            setLoading(false);
            goodToast("Novo usuário cadastrado!");
            return handleClose();
        }
        setLoading(false);
        return badToast("Erro ao cadastrar usuário");
    
    }

    return (
        <Modal handleClose={handleClose}>
            <h2>Cadastro de Usuário</h2>
            <div className="grid grid-cols-2 w-fit m-auto gap-3 mt-10">
                <label>Nome: </label>
                <input type="text" value={name} onChange={e => setName(e.target.value)} className="text-black text-center"/>

                <label>Email: </label>
                <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="text-black text-center"/>

                <label>Nascimento: </label>
                <input type="text" value={birth} onChange={e => setFormattedBirth(e.target.value)} className="text-black text-center"/>

                <label>Telefone: </label>
                <input type="text" value={phone} onChange={e => setPhone(e.target.value)} className="text-black text-center"/>

                <label>CPF: </label>
                <input type="text" value={docNumber} onChange={e => setDocNumber(e.target.value)} className="text-black text-center"/>

                <label>Senha: </label>
                <input type="text" value={pass} onChange={e => setPass(e.target.value)} className="text-black text-center"/>
            </div>
            <div className="flex w-1/2 m-auto mt-10">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit} disabled={loading}>Cadastrar</Button>
            </div>
            {loading ? <Loading classes="m-auto my-2"/> : null}
        </Modal>
    )
}

export default RegisterModal;