import Button from "../atoms/button";
import { formatarData } from "../utils/functions";
import Modal from "../utils/modal"

const TransactionModal = ({ setEdit, editableTransaction, refresh }) => {

    const handleClose = () => {
        setEdit(false);
    }

    // const handleSubmit = () => {

    // }

    const createdAt = editableTransaction.createdAt ? formatarData(new Date(editableTransaction.createdAt)) : "-";

    return (
        <Modal handleClose={handleClose}>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableTransaction._id} className="text-black text-center m-2 w-full" disabled/>
            </div>

            <p className="my-4">ID produto: {editableTransaction.product?._id}</p>
            <p className="my-4">ID Pagamento (Getnet): {editableTransaction.data?.payment_id}</p>
            <p className="my-4">Usuário: {editableTransaction.user?.email}</p>
            <p className="my-4">Valor: {"R$"+(editableTransaction.product?.finalPrice/100).toFixed(2).replace(".", ",")}</p>
            <p className="my-4">Modalidade: {editableTransaction.kind}</p>
            <p className="my-4">Status: {editableTransaction.data?.status}</p>

            <div className="w-1/2 flex m-auto">Transação criada em: <span className="text-right flex-1">{createdAt}</span></div>

            <div className="flex w-1/2 mx-auto my-4">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Fechar</Button>
                {/* <Button classes="bg-green-400 hover:bg-green-600 w-fit" click={handleSubmit}>Ver na GetNet</Button> */}
            </div>

        </Modal>
    )
}

export default TransactionModal;
