import { TRANSACTIONS_URL } from "../../context/paths";

export const getTransactions = async (token, productId, email) => {
    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    let queryUrl = TRANSACTIONS_URL;
    const params = new URLSearchParams();
    if(productId){
        params.append("productId", productId);
    }
    if(email){
        params.append("user_email", email);
    }
    if(params){
        queryUrl += "?"+params.toString();
    }
    try{
        const response = await fetch(queryUrl, searchOptions);
        return await response.json();
    }
    catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}
