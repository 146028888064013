import { useContext, useState } from "react";
import Loading from "../atoms/loading";
import StoresList from "./storesList";
import ToastContext from "../../context/toastProvider";
import Button from "../atoms/button";
import { getStores } from "./functions";
import StoreModal from "./storeModal";
import CreateStoreModal from "./createStoreModal";

const Stores = ({ user }) => {

    const { badToast } = useContext(ToastContext);

    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editableStore, setEditableStore] = useState({});
    const [register, setRegister] = useState(false);

    const searchStores = async () => {
        const data = await getStores(user.token);
        setStores(data);
        setLoading(false);
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    const handleSearch = () => {
        setLoading(true)
        searchStores();
    }

    return (
        <main className="text-center">
            <h1>Lojas</h1>
            <div className="border-b border-t border-[#d59106] p-2">
                <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline">Pesquisar Lojas</Button>
                <Button classes="bg-blue-300 hover:bg-blue-400 inline ml-10" click={() => setRegister(true)}>Cadastrar Loja</Button>
            </div>
            {
                loading ? <Loading classes="m-auto"/>
                : stores?.type === "success" ?
                <StoresList stores={stores.data} setEdit={setEdit} setEditableStore={setEditableStore}/>
                : stores?.type === "error" ?
                <div>
                    <p className="text-center m-8 text-red-600">{stores.message}</p>
                </div>
                : null
            }
            {
                edit ? <StoreModal setEdit={setEdit} editableStore={editableStore} refresh={handleSearch}/> : null
            }
            {
                register ? <CreateStoreModal setRegister={setRegister} refresh={handleSearch}/> : null
            }
        </main>
    )
}

export default Stores;