import { useState } from "react";
import Button from "../atoms/button";

const SerialsList = ({ serials, setEdit, setEditableSerial }) => {

    const [page, setPage] = useState(1);

    const gmsLen = serials.length;
    const perPage = 100;
    let gmsPages;
    if(gmsLen % perPage === 0){
        gmsPages = Math.floor(gmsLen / perPage) || 1;
    }else{
        gmsPages = Math.floor(gmsLen / perPage)+1 || 1;
    }

    const paginatedSerials = serials.slice((page-1)*perPage, page*perPage);

    const handleEdit = (serial) => {
        setEdit(true);
        setEditableSerial(serial);
    }

    const nextPage = () => {
        if(page<gmsPages){
            setPage(page+1);
        }
    }

    const previousPage = () => {
        if(page>1){
            setPage(page-1);
        }
    }

    return (
        <div>
            <table className="text-center m-auto gap-5">
                <thead>
                    <tr>
                        <th className="p-4">Jogo</th>
                        <th className="p-4">Edição</th>
                        <th className="p-4">Ano</th>
                        <th className="p-4">Nome</th>
                        <th className="p-4">Serial</th>
                        <th className="p-4">Nota</th>
                        <th className="p-4"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        paginatedSerials.map((serial, idx) => (
                            <tr key={idx}>
                                <td className="p-4">{serial.gradableModel.game}</td>
                                <td className="p-4">{serial.gradableModel.edition}</td>
                                <td className="p-4">{serial.gradableModel.year}</td>
                                <td className="p-4">{serial.gradableModel.name}</td>
                                <td className="p-4">{serial.serialNumber}</td>
                                <td className="p-4">{serial.generalScore}</td>
                                <td className="p-4"><Button classes="bg-blue-500 hover:bg-blue-800" click={() => handleEdit(serial)}>Atualizar</Button></td>
                            </tr>
            
                        ))
                    }
                </tbody>
            </table>
            <div className="my-4">
                <label>Página </label>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={previousPage} disabled={page===1}>
                    {"< "}
                </Button>
                <h3 className="inline mx-4">{page}</h3>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={nextPage} disabled={page===gmsPages}>
                    {" >"}
                </Button>
                <h3 className="inline mx-4"> de {gmsPages}</h3>
            </div>
        </div>
    )
}

export default SerialsList;