import { SERIALS_URL } from "../../context/paths";

export const getSerials = async (token, modelId, serial) => {
    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    let queryUrl = SERIALS_URL;
    const params = new URLSearchParams();
    if(modelId){
        params.append("modelId", modelId)
    }
    if(serial){
        params.append("serial", serial)
    }
    if(params){
        queryUrl += "?"+params.toString();
    }

    try{
        const response = await fetch(queryUrl, searchOptions);
        return await response.json();
    }catch(error){
        return {
            message: "Error retrieving serials",
            type: "error"
        }
    }
    
}

export const createSerial = async (token, body) => {
    const createOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const CREATE_SERIAL_URL = `${SERIALS_URL}/add`;
    try{
        const response = await fetch(CREATE_SERIAL_URL, createOptions);
        return await response.json();
    }catch(error){
        return {
            message: "Error creating serial",
            type: "error"
        }
    }
}

export const updateSerial = async (token, id, body) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }
    const UPDATE_SERIAL_URL = `${SERIALS_URL}/update/${id}`;
    try{
        const response = await fetch(UPDATE_SERIAL_URL, updateOptions);
        return await response.json();
    }catch(error){
        return {
            message: "Error updating serial",
            type: "error"
        }
    }
}
