import { useContext, useState } from "react";
import Modal from "../utils/modal";
import { updateSerial } from "./functions";
import AuthContext from "../../context/authProvider";
import ToastContext from "../../context/toastProvider";
import Button from "../atoms/button";

const SerialModal = ({ setEdit, editableSerial, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [gradableModel, setGradableModel] = useState(editableSerial.gradableModel._id);
    const [productId, setProductId] = useState(editableSerial.product);
    const [serialNumber, setSerialNumber] = useState(editableSerial.serialNumber);
    const [generalScore, setGeneralScore] = useState(editableSerial.generalScore);
    const [surface, setSurface] = useState(editableSerial.surface);
    const [edges, setEdges] = useState(editableSerial.edges);
    const [centering, setCentering] = useState(editableSerial.centering);
    const [corners, setCorners] = useState(editableSerial.corners);
    const [enableChanges, setEnableChanges] = useState(false);

    const handleChanges = () => {
        let changes = {};
        if(gradableModel !== editableSerial.gradableModel._id){
            changes.gradableModel = gradableModel;
        }
        if(serialNumber !== editableSerial.serialNumber){
            changes.serialNumber = serialNumber;
        }
        if(generalScore !== editableSerial.generalScore){
            changes.generalScore = generalScore;
        }
        if(surface !== editableSerial.surface){
            changes.surface = surface;
        }
        if(edges !== editableSerial.edges){
            changes.edges = edges;
        }
        if(centering !== editableSerial.centering){
            changes.centering = centering;
        }
        if(corners !== editableSerial.corners){
            changes.corners = corners;
        }
        if(productId !== editableSerial.product){
            changes.productId = productId; 
        }

        return changes;
    }

    const handleSubmit = async () => {
        const body = handleChanges();
        const data = await updateSerial(user.token, editableSerial._id, body);
        if(data.type === "success"){
            goodToast("Serial atualizado");
            handleClose();
            refresh();
        }
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    const handleClose = () => {
        setEdit(false);
    }

    return(
        <Modal handleClose={handleClose}>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableSerial._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            <div className="grid grid-cols-2 gap-2 w-fit m-auto">
                <label>ID do Modelo: </label>
                <input type="text" value={gradableModel} onChange={e => setGradableModel(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>ID do Pedido: </label>
                <input type="text" value={productId} onChange={e => setProductId(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Número de serial: </label>
                <input type="text" value={serialNumber} onChange={e => setSerialNumber(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Nota geral: </label>
                <input type="text" value={generalScore} onChange={e => setGeneralScore(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Surface: </label>
                <input type="text" value={surface} onChange={e => setSurface(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Edges: </label>
                <input type="text" value={edges} onChange={e => setEdges(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Centering: </label>
                <input type="text" value={centering} onChange={e => setCentering(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
                <label>Corners: </label>
                <input type="text" value={corners} onChange={e => setCorners(e.target.value)} className="text-center text-black" disabled={!enableChanges}/>
            </div>
            <Button classes="bg-[#d59106] hover:bg-orange-500 my-4" click={() => setEnableChanges(!enableChanges)}>{enableChanges ? "Bloquear alterações" : "Liberar alterações"}</Button>
            <div className="flex w-1/2 mx-auto my-4">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit} disabled={!enableChanges}>Atualizar</Button>
            </div>

        </Modal>
    )
}

export default SerialModal;
