const UserAddress = ({ editableUser }) => {
    return (
        <div>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableUser._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            <div className="m-4">
            <p className="my-4">Cidade: {editableUser?.city}</p>
            <p className="my-4">Bairro: {editableUser?.district}</p>
            <p className="my-4">Rua: {editableUser?.street}</p>
            <p className="my-4">Número: {editableUser?.houseNumber}</p>
            <p className="my-4">Complemento: {editableUser?.complement}</p>
            <p className="my-4">CEP: {editableUser?.postalCode}</p>
            </div>


        </div>
    )
}

export default UserAddress;