import { useEffect, useState } from "react";
import { getPartnerGeneralInfo } from "./functions";
import Loading from "../atoms/loading";

const PartnerHome = ({ user }) => {
    
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const doGetGeneralInfo = async () => {
        setLoading(true);
        const getData = await getPartnerGeneralInfo(user.token);
        setData(getData.data);
        setLoading(false);
    }

    useEffect(() => {
        doGetGeneralInfo();
        // eslint-disable-next-line
    }, [])

    return (
        <main className="text-center">
            {
                !user.store ?
                <p className="mt-4">Você não possui um reino parceiro</p>
                : <p className="mt-4">Bem-vindo(a) à seção de {user.store.name}</p>
            }
            {
                loading ? <Loading classes={"m-auto"}/>
                :
                <div className="grid grid-cols-2 w-fit m-auto gap-3 mt-10">
                    <label>Pedidos com pagamento aprovado: </label>
                    <label>{data.approved || " - "}</label>

                    <label>Pedidos aguardando Manafix: </label>
                    <label>{data.hold || " - "}</label>

                    <label>Pedidos aguardando cliente: </label>
                    <label>{data.awaiting || " - "}</label>

                    <label>Pedidos entregues (aguardando finalização): </label>
                    <label>{data.delivered || " - "}</label>
                </div>
            }
        </main>
    )
}

export default PartnerHome;