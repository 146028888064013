import { useContext, useState } from "react";
import Modal from "../utils/modal"
import Button from "../atoms/button";
import Loading from "../atoms/loading";
import { addStore } from "./functions";
import AuthContext from "../../context/authProvider";
import ToastContext from "../../context/toastProvider";

const CreateStoreModal = ({ setRegister, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [address, setAddress] = useState("");
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setRegister(false);
    }

    const handleSubmit = async () => {
        if(name === ""){
            return badToast("Insira um nome");
        }
        if(city === ""){
            return badToast("Insira uma cidade");
        }
        if(state.length !== 2){
            return badToast("Sigla de estado inválida");
        }
        if(address === ""){
            return badToast("Insira um endereço");
        }
        
        setLoading(true);
        
        const createBody = {
            name,
            city,
            state,
            address,
            active
        }

        const data = await addStore(user.token, createBody);
        if(data.type === "success"){
            setLoading(false);
            goodToast("Nova loja cadastrada!");
            refresh();
            return handleClose();
        }
        setLoading(false);
        return badToast("Erro ao cadastrar loja");
    }
    
    return (
        <Modal handleClose={handleClose}>
            <div className="my-4">
                <label>Nome: </label>
                <input type="text" value={name} onChange={e => setName(e.target.value)} className="text-black text-center mr-2 w-1/2"/>
                <label>Ativa: </label>
                <input type="checkbox" value={active} onChange={e => setActive(!active)} className="text-black text-center"/>
            </div>
            <div className="grid grid-cols-[1fr,2fr] mx-10 my-4 gap-y-2">
                <label>Cidade: </label>
                <input type="text" value={city} onChange={e => setCity(e.target.value)} className="text-black text-center"/>
                <label>Estado: </label>
                <input type="text" value={state} onChange={e => setState(e.target.value)} className="text-black text-center"/>
                <label>Endereço: </label>
                <input type="text" value={address} onChange={e => setAddress(e.target.value)} className="text-black text-center"/>
            </div>
            <div className="flex w-1/2 m-auto mt-10">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit}>Criar</Button>
            </div>
            {loading ? <Loading classes="m-auto my-2"/> : null}
        </Modal>
    )
}

export default CreateStoreModal;
