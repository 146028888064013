import { useContext, useState } from "react";
import Loading from "../atoms/loading";
import Button from "../atoms/button";
import ToastContext from "../../context/toastProvider";
import { getLogs } from "./functions";
import LogsList from "./logsList";
import LogModal from "./logModal";

const Logs = ({ user }) => {

    const { badToast } = useContext(ToastContext);

    const [target, setTarget] = useState("");
    const [email, setEmail] = useState("");
    const [targetId, setTargetId] = useState("");
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editableLog, setEditableLog] = useState({});

    const searchLogs = async () => {
        const data = await getLogs(user.token, target, targetId, email, from, to);
        setLogs(data);
        setLoading(false)
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    const handleSearch = async () => {
        setLoading(true)
        searchLogs();
    }

    return (
        <main className="text-center">
            <h1>Logs</h1>
            <div className="border-b border-t border-[#d59106] p-2">
                <label>Alvo: </label>
                <select className="text-black text-center p-1" name="target" value={target} onChange={e => setTarget(e.target.value)}>
                    <option value="">Todos</option>
                    <option value="user">Usuários</option>
                    <option value="product">Produtos</option>
                    <option value="store">Lojas</option>
                    <option value="gradablemodel">Modelos</option>
                    <option value="serial">Seriais</option>
                </select>
                <input className="m-2 text-black" type="text" placeholder="ID do alvo" value={targetId} onChange={e => setTargetId(e.target.value)}/>
                <input className="m-2 text-black" type="text" placeholder="Email do usuário" value={email} onChange={e => setEmail(e.target.value)}/>
                <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline ml-4" disabled={from === "" || to === ""}>Pesquisar</Button>
                <div>
                    <label>De: </label>
                    <input type="text" placeholder="dd/mm/aaaa" className="text-black mr-2" value={from} onChange={e => setFrom(e.target.value)}/>
                    <label>Até: </label>
                    <input type="text" placeholder="dd/mm/aaaa" className="text-black" value={to} onChange={e => setTo(e.target.value)}/>
                </div>
            </div>
            {
                loading ? <Loading classes="m-auto"/>
                : logs?.type === "success" ?
                <LogsList logs={logs.data} setEdit={setEdit} setEditableLog={setEditableLog}/>
                : logs?.type === "error" ?
                <div>
                    <p className="text-center m-8 text-red-600">{logs.message}</p>
                </div>
                : null
            }
            {
                edit ? <LogModal setEdit={setEdit} editableLog={editableLog}/> : null
            }
        </main>
    )
}

export default Logs;
