import { useContext, useState } from "react";
import Loading from "../atoms/loading";
import Button from "../atoms/button";
import { getSerials } from "./functions";
import SerialsList from "./serialsList";
import ToastContext from "../../context/toastProvider";
import CreateSerialModal from "./createSerialModal";
import SerialModal from "./serialModal";

const Serials = ({ user }) => {

    const { badToast } = useContext(ToastContext);

    const [searchModel, setSearchModel] = useState("");
    const [searchSerial, setSearchSerial] = useState("");
    const [serials, setSerials] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editableSerial, setEditableSerial] = useState({});
    const [register, setRegister] = useState(false);

    const searchSerials = async () => {
        const data = await getSerials(user.token, searchModel, searchSerial);
        setSerials(data);
        setLoading(false)
        if(serials.type === "error"){
            badToast(serials.message);
        }
    }

    const handleSearch = () => {
        setLoading(true)
        searchSerials();
    }

    return (
        <main className="text-center">
            <h1>Seriais</h1>
            <div className="border-b border-t border-[#d59106] p-2">
                <input className="m-2 text-black" type="text" placeholder="ID do Modelo" value={searchModel} onChange={e => setSearchModel(e.target.value)}/>
                <input className="m-2 text-black" type="text" placeholder="Serial" value={searchSerial} onChange={e => setSearchSerial(e.target.value)}/>
                <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline ml-4">{searchModel === "" && searchSerial === "" ? "Pesquisar todos" : "Pesquisar"}</Button>
                <Button classes="bg-blue-300 hover:bg-blue-400 inline ml-2" click={() => setRegister(true)}>Cadastrar Serial</Button>
            </div>
            {
                loading ? <Loading classes="m-auto"/>
                : serials?.type === "success" ?
                <SerialsList serials={serials.data} setEdit={setEdit} setEditableSerial={setEditableSerial}/>
                : serials?.type === "error" ?
                <div>
                    <p className="text-center m-8 text-red-600">{serials.message}</p>
                </div>
                : null
            }
            {
                edit ? <SerialModal setEdit={setEdit} editableSerial={editableSerial} refresh={handleSearch}/>
                : null
            }
            {
                register ? <CreateSerialModal setRegister={setRegister} refresh={handleSearch}/> : null
            }
        </main>
    )
}

export default Serials;