import { useContext, useState } from "react";
import Modal from "../utils/modal"
import AuthContext from "../../context/authProvider";
import ToastContext from "../../context/toastProvider";
import Button from "../atoms/button";
import { updateCoupon } from "./functions";

const CouponModal = ({ setEdit, editableCoupon, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [code, setCode] = useState(editableCoupon.code);
    const [description, setDescription] = useState(editableCoupon.description);
    const [value, setValue] = useState(editableCoupon.value);
    const [kind, setKind] = useState(editableCoupon.kind);
    const [active, setActive] = useState(editableCoupon.isActive);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setEdit(false);
    }

    const handleSubmit = async () => {
        setLoading(true);
        const body = {
            code,
            description,
            value,
            kind,
            isActive: active
        }
        const data = await updateCoupon(user.token, editableCoupon._id, body);
        if(data.type === "success"){
            setLoading(false);
            goodToast("Cupom atualizado!");
            refresh();
            return handleClose();
        }
        setLoading(false);
        return badToast("Erro ao atualizar cupom");
    }

    const disableButton = (code === editableCoupon.code && description === editableCoupon.description && value === editableCoupon.value && kind === editableCoupon.kind && active === editableCoupon.isActive) || loading;

    return (
        <Modal handleClose={handleClose}>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableCoupon._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            <div className="grid grid-cols-2 w-fit m-auto gap-3 mt-10">
                <label>Código: </label>
                <input type="text" value={code} onChange={e => setCode(e.target.value)} className="text-black text-center"/>

                <label>Descrição: </label>
                <textarea rows="2" value={description} onChange={e => setDescription(e.target.value)} className="text-black text-center"/>

                <label>Valor: </label>
                <input type="text" value={value} onChange={e => setValue(e.target.value)} className="text-black text-center"/>

                <label>Tipo: </label>
                <div>
                    <label className="mx-2">
                    Percentual <input type="radio" name="kind" value="percent" checked={kind === "percent"} onChange={e => setKind(e.target.value)}/>
                    </label>
                    <label>
                    Quantia <input type="radio" name="kind" value="amount" checked={kind === "amount"} onChange={e => setKind(e.target.value)}/>
                    </label>
                </div>
                <label>Ativo</label>
                <input type="checkbox" checked={active} onChange={e => setActive(!active)}/>
            </div>
            <div className="flex w-1/2 m-auto mt-4">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit} disabled={disableButton}>Salvar</Button>
            </div>
        </Modal>
    )
}

export default CouponModal;
