import Modal from "../utils/modal"

const LogModal = ({ setEdit, editableLog }) => {

    const handleClose = () => {
        setEdit(false);
    }
    
    const targetNames = {
        user: "Usuário",
        product: "Produto",
        store: "Loja",
        gradablemodel: "Modelo",
        serial: "Serial",
        coupon: "Cupom"
    }

    return(
        <Modal handleClose={handleClose}>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableLog._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            <p className="my-4">{editableLog.details}</p>
            <p className="my-4">Alvo: {targetNames[editableLog.target]}</p>
            <p className="my-4">ID do alvo: {editableLog.targetId}</p>
            <p className="my-4">Usuário: {editableLog.user.email}</p>
            <h3 className="text-center text-orange-500">CAMPOS ALTERADOS</h3>
            <table className="m-auto">
                <thead>
                    <tr>
                        <th className="border border-white p-2">Campo</th>
                        <th className="border border-white p-2">Antes</th>
                        <th className="border border-white p-2">Depois</th>
                    </tr>
                </thead>
                <tbody>
                {
                    Object.keys(editableLog.fields).map((field, idx) => {
                        const log = editableLog.fields[field];
                        return (
                            <tr key={idx}>
                                <td className="border border-white p-2">{field}</td>
                                <td className="border border-white p-2">{log.prev === true ? "✔" : log.prev === false ? "X" : log.prev}</td>
                                <td className="border border-white p-2">{log.after === true ? "✔" : log.after === false ? "X" : log.after}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>


        </Modal>
    )
}

export default LogModal;
