import { useState } from "react";
import { createContext } from "react";
import { LOGIN_URL, DECODE_URL, REFRESH_URL } from "./paths";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [status, setStatus] = useState("loading");
    const [message, setMessage] = useState(null);

    const login = async (body) => {
        setStatus("loading");
        const { email, password } = body;
        try{
            const loginOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({email, password})
            }
    
            const response = await fetch(LOGIN_URL, loginOptions);
            const data = await response.json();
    
            if(data.refreshToken){
    
                const currentDate = new Date();
                const expirationDate = new Date(currentDate.getTime() + 90 * 24 * 60 * 60 * 1000);
                const expires = `expires=${expirationDate.toUTCString()}`;
                const cookieString = `keepersPhrase=${data.refreshToken}; ${expires}; Secure; SameSite=Lax;`
                document.cookie = cookieString;
    
                return decodeToken(data.accessToken);
            }
            setMessage(data.message);
            setStatus(data.type);
            return data;
        }catch(error){
            setMessage("Erro na comunicação com o servidor");
            setStatus("error");
        }
    }

    const decodeToken = async (accessToken) => {
        try{
            const decodeOptions = {
                headers: {
                    "Content-Type": "application/json",
                    "authorization": `Bearer ${accessToken}`
                }
            }
            const response = await fetch(DECODE_URL, decodeOptions);
            const data = await response.json();
            setStatus(data.type);
            if(response.ok){
                const {name, birth, email, verified, roles, store} = data.user;
                setUser({name, birth, email, verified, roles, store, token: accessToken});
                return;
            }
            return;
        }catch(error){
            setMessage("Erro na comunicação com o servidor");
            setStatus("error");
        }
    }

    const logout = () => {
        setStatus("loading");
        deleteCookie("keepersPhrase");
        setUser(null);
        setStatus("idle");
    }

    const retrieveRefreshToken = async (refreshToken) => {
        try{
            setStatus("loading");
            if(refreshToken) {
                const refreshOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({refreshToken})
                }
                const response = await fetch(REFRESH_URL, refreshOptions);
                const data = await response.json();
                if(data.accessToken){
                    return decodeToken(data.accessToken);
                }
                setMessage(data.message);
                setStatus(data.type);
                return data;
            }
            setStatus("idle");
            return;
        }catch(error){
            setMessage("Erro na comunicação com o servidor");
            setStatus("error");
        }
    }

    const getCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    }
    
    const deleteCookie = (name) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1993 00:00:00 UTC; Secure; SameSite=Lax`;
    }

    return(
        <AuthContext.Provider value={
            {
                user, setUser,
                login, logout,
                getCookie, retrieveRefreshToken,
                status, setStatus, message
            }
        }>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;