import { useContext, useState } from "react";
import Button from "../../atoms/button";
import { updateUserInfo } from "../functions";
import AuthContext from "../../../context/authProvider";
import ToastContext from "../../../context/toastProvider";

const UserInfo = ({ editableUser, handleClose, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [name, setName] = useState(editableUser.name);
    const [email, setEmail] = useState(editableUser.email);
    const [isVerified, setIsVerified] = useState(editableUser.verified);
    const [isAdmin, setIsAdmin] = useState(editableUser.roles.includes("admin"));
    const [isPartner, setIsPartner] = useState(editableUser.roles.includes("partner"));

    const handleChanges = () => {
        let changes = {};
        if(name !== editableUser.name){
            changes.name = name;
        }
        if(email !== editableUser.email){
            changes.email = email;
        }
        if(isVerified !== editableUser.verified){
            changes.verified = isVerified;
        }

        let roles = ["customer"];
        if(isAdmin) roles.push("admin");
        if(isPartner) roles.push("partner");
        
        const verif1 = roles.every(item => editableUser.roles.includes(item));
        const verif2 = editableUser.roles.every(item => roles.includes(item));

        if(roles.length !== editableUser.roles.length || !verif1 || !verif2){
            changes.roles = roles;
        }
        return changes;
    }


    const handleSubmit = async () => {
        const body = handleChanges();
        const data = await updateUserInfo(user.token, editableUser._id, body);
        if(data.type === "success"){
            goodToast("Usuário atualizado");
            handleClose();
            refresh();
        }
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    return (
        <div>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableUser._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            <div className="m-4">
                <label>Nome: </label>
                <input type="text" value={name} onChange={e => setName(e.target.value)} className="text-black text-center mr-2"/>
                <label>Email: </label>
                <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="text-black text-center"/>
            </div>
            <p className="mb-4 border w-fit m-auto px-4 py-2"><strong>Telefone:</strong> {editableUser.phone}</p>
            <div className="grid grid-cols-2 w-fit m-auto">
                
                <label className="m-auto">Google User: </label>
                <label className={`m-auto ${editableUser.isGoogle ? "text-green-600" : "text-red-600"}`}>{editableUser.isGoogle ? "✔" : "X"}</label>

                <label className="m-auto">Verificado: </label>
                <input type="checkbox" className="m-4" checked={isVerified} onChange={() => setIsVerified(!isVerified)}/>

                <label className="m-auto">Admin: </label>
                <input type="checkbox" className="m-4" checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)}/>

                <label className="m-auto">Parceiro: </label>
                <input type="checkbox" className="m-4" checked={isPartner} onChange={() => setIsPartner(!isPartner)}/>
            </div>
            <div className="flex w-1/2 m-auto ">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit}>Salvar</Button>
            </div>
        </div>
    )
}

export default UserInfo;