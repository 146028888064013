import { LOGS_URL } from "../../context/paths";

export const getLogs = async (token, target, targetId, email, from, to) => {
    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    let queryUrl = LOGS_URL;
    const params = new URLSearchParams();
    if(target){
        params.append("target", target);
    }
    if(targetId){
        params.append("target_id", targetId);
    }
    if(email){
        params.append("user_email", email);
    }
    if(from){
        params.append("from", from.replace(/\//g, ""));
    }
    if(to){
        params.append("to", to.replace(/\//g, ""));
    }
    if(params){
        queryUrl += "?"+params.toString();
    }

    try{
        const response = await fetch(queryUrl, searchOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }    
}
