import { useEffect, useState } from "react";
import { getGeneralInfo } from "./functions";
import Loading from "../atoms/loading";

const Home = ({ user }) => {
    
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const doGetGeneralInfo = async () => {
        setLoading(true);
        const getData = await getGeneralInfo(user.token);
        setData(getData.data);
        setLoading(false);
    }

    useEffect(() => {
        doGetGeneralInfo();
        // eslint-disable-next-line
    }, [])

    return (
        <main className="text-center p-4 flex m-auto">
            {
                loading ? <Loading classes={"m-auto"}/>
                :
                <div className="grid grid-cols-2 w-fit m-auto gap-3 mt-10">
                    <label>Usuários registrados: </label>
                    <label>{data.users || " - "}</label>

                    <label>Seriais cadastrados: </label>
                    <label>{data.serials || " - "}</label>

                    <label>Pedidos com pagamento aprovado: </label>
                    <label>{data.approved || " - "}</label>

                    <label>Pedidos enviados para Manafix (Correios): </label>
                    <label>{data.sent || " - "}</label>

                    <label>Pedidos aguardando Manafix: </label>
                    <label>{data.hold || " - "}</label>

                    <label>Pedidos entregues (aguardando finalização): </label>
                    <label>{data.delivered || " - "}</label>
                </div>
            }
        </main>
    )
}

export default Home;
