import Button from "../atoms/button";

const StoresList = ({ stores, setEdit, setEditableStore}) => {

    const handleEdit = (store) => {
        setEdit(true);
        setEditableStore(store);
    }

    return (
        <table className="text-center m-auto gap-5">
            <thead>
                <tr>
                    <th className="p-4">ID</th>
                    <th className="p-4">Nome</th>
                    <th className="p-4">Cidade</th>
                    <th className="p-4">Estado</th>
                    <th className="p-4">Ativa</th>
                    <th className="p-4">Headquarter</th>
                    <th className="p-4"></th>
                </tr>
            </thead>
            <tbody>
                {
                    stores.map((store, idx) => (
                        <tr key={idx}>
                            <td className="p-4">{store._id}</td>
                            <td className="p-4">{store.name}</td>
                            <td className="p-4">{store.city}</td>
                            <td className="p-4">{store.state}</td>
                            <td className="text-green-600 p-4">{store.active ? "✔" : ""}</td>
                            <td className="text-green-600 p-4">{store.headquarter ? "✔" : ""}</td>
                            <td className="p-4"><Button classes="bg-blue-500 hover:bg-blue-800" click={() => handleEdit(store)}>Atualizar</Button></td>
                        </tr>
                        
                    ))
                }
            </tbody>
        </table>
    )
}

export default StoresList;
