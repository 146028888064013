import { useState } from "react";
import Modal from "../../utils/modal";
import GradableModelInfos from "./gradableModelInfo";
import GradableModelSerials from "./gradableModelSerials";
import RegisterSerial from "./registerSerial";


const GradableModelModal = ({ setEdit, editableGradableModel, refresh }) => {

    const [tab, setTab] = useState(0);

    const handleClose = () => {
        setEdit(false);
    }

    const activeTab = "bg-[#d59106] text-black px-2 cursor-pointer";
    const inactiveTab = "border border-[#d59106] px-2 cursor-pointer";
    //const disabledTab = "bg-gray-700 text-black px-2";

    return (
        <Modal handleClose={handleClose}>
            <div className="text-left h-8 flex gap-2"  onClick={(e) => e.stopPropagation()}>
                <span className={tab === 0 ? activeTab : inactiveTab} onClick={() => setTab(0)}>
                    Infos
                </span>
                <span className={tab === 1 ? activeTab : inactiveTab} onClick={() => setTab(1)}>
                    Seriais
                </span>
                <span className={tab === 2 ? activeTab : inactiveTab} onClick={() => setTab(2)}>
                    Cadastrar
                </span>
            </div>
            {
                tab === 0 ?
                <GradableModelInfos editableGradableModel={editableGradableModel} handleClose={handleClose} refresh={refresh}/>
                : tab === 1 ?
                <GradableModelSerials editableGradableModel={editableGradableModel} handleClose={handleClose} refresh={refresh}/>
                : tab === 2?
                <RegisterSerial editableGradableModel={editableGradableModel} handleClose={handleClose}/>
                : null
            }
        </Modal>
    )
}

export default GradableModelModal;
