import { useState } from "react";
import Button from "../atoms/button";
import { statusObject } from "../utils/allProductStatus";
import { cashnator, formatarData } from "../utils/functions";

const ProductsList = ({ products, setEdit, setEditableProduct }) => {

    const [page, setPage] = useState(1);

    const gmsLen = products.length;
    const perPage = 100;
    let gmsPages;
    if(gmsLen % perPage === 0){
        gmsPages = Math.floor(gmsLen / perPage) || 1;
    }else{
        gmsPages = Math.floor(gmsLen / perPage)+1 || 1;
    }

    const paginatedProducts = products.slice((page-1)*perPage, page*perPage);

    const handleEdit = (product) => {
        setEdit(true);
        setEditableProduct(product);
    }

    const nextPage = () => {
        if(page<gmsPages){
            setPage(page+1);
        }
    }

    const previousPage = () => {
        if(page>1){
            setPage(page-1);
        }
    }

    return (
        <div>
            <table className="text-center m-auto gap-5">
                <thead>
                    <tr>
                        <th className="p-4">Nome Cliente</th>
                        <th className="p-4">Nº Cartas</th>
                        <th className="p-4">Entrega</th>
                        <th className="p-4">Preço</th>
                        <th className="p-4">Prazo</th>
                        <th className="p-4">Criado</th>
                        <th className="p-4">Status</th>
                        <th className="p-4"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        paginatedProducts.map((product, idx) => (
                            <tr key={idx}>
                                <td className="p-4">{product.user?.name}</td>
                                <td className="p-4">{product.numberOfCards}</td>
                                <td className="p-4">{product.delivery}</td>
                                <td className="p-4">{cashnator(product.finalPrice)}</td>
                                <td className="p-4">{product.deadline}</td>
                                <td className="p-4">{formatarData(product.createdAt)}</td>
                                <td className="p-4">{statusObject[product.status]}</td>
                                <td className="p-4"><Button classes="bg-blue-500 hover:bg-blue-800" click={() => handleEdit(product)}>Atualizar</Button></td>
                            </tr>
            
                        ))
                    }
                </tbody>
            </table>
            <div className="my-4">
                <label>Página </label>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={previousPage} disabled={page===1}>
                    {"< "}
                </Button>
                <h3 className="inline mx-4">{page}</h3>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={nextPage} disabled={page===gmsPages}>
                    {" >"}
                </Button>
                <h3 className="inline mx-4"> de {gmsPages}</h3>
            </div>
        </div>
    )
}

export default ProductsList;