import { useContext, useState } from "react";
import Modal from "../utils/modal"
import AuthContext from "../../context/authProvider";
import ToastContext from "../../context/toastProvider";
import { createSerial } from "./functions";
import Button from "../atoms/button";
import { generateSerial, getGradableModels } from "../gradableModels/functions";

const CreateSerialModal = ({ setRegister, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);
    const [gradableModelId, setGradableModelId] = useState("");
    const [productId, setProductId] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [generalScore, setGeneralScore] = useState("");
    const [surface, setSurface] = useState("");
    const [edges, setEdges] = useState("");
    const [centering, setCentering] = useState("");
    const [corners, setCorners] = useState("");


    const handleClose = () => {
        setRegister(false);
    }

    const handleSubmit = async () => {
        const body = {
            gradableModelId,
            serialNumber,
            gradedAt: new Date(),
            generalScore,
            surface,
            edges,
            centering,
            corners,
            productId
        };
        const data = await createSerial(user.token, body);
        if(data.type === "success"){
            goodToast("Serial cadastrado");
            handleClose();
            refresh();
        }
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    const doGenerateSerial = async () => {
        if(!gradableModelId){
            badToast("Insira um ID de modelo!")
            return null;
        }
        const gradableModelResponse = await getGradableModels(user.token, gradableModelId);
        if(gradableModelResponse.type === "error"){
            badToast(gradableModelResponse.message);
            return null;
        }
        const gradableModel = gradableModelResponse.data[0];
        const data = await generateSerial(user.token, gradableModel.serialInitial || "XX");
        if(data.type === "error"){
            badToast(data.message);
            return null;
        }
        if(data.type === "success"){
            setSerialNumber(data.data);
        }
    }

    return (
        <Modal handleClose={handleClose}>
            <h2 className="my-6 text-orange-500">NOVO SERIAL</h2>
            <div className="grid grid-cols-2 gap-2 w-fit m-auto">
                <label>ID do Modelo Avaliável: </label>
                <input type="text" value={gradableModelId} onChange={e => setGradableModelId(e.target.value)} className="text-center text-black"/>
                <label>ID do Pedido: </label>
                <input type="text" value={productId} onChange={e => setProductId(e.target.value)} className="text-center text-black"/>
                <label>Número de Serial: </label>
                <div className="flex">
                    <label>{serialNumber}</label>
                    <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={doGenerateSerial}>Gerar</Button>
                </div>
                <label>Nota Geral: </label>
                <input type="text" value={generalScore} onChange={e => setGeneralScore(e.target.value)} className="text-center text-black"/>
                <label>Surface: </label>
                <input type="text" value={surface} onChange={e => setSurface(e.target.value)} className="text-center text-black"/>
                <label>Edges: </label>
                <input type="text" value={edges} onChange={e => setEdges(e.target.value)} className="text-center text-black"/>
                <label>Centering: </label>
                <input type="text" value={centering} onChange={e => setCentering(e.target.value)} className="text-center text-black"/>
                <label>Corners: </label>
                <input type="text" value={corners} onChange={e => setCorners(e.target.value)} className="text-center text-black"/>
            </div>
            <div className="flex w-1/2 mx-auto my-4">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit}>Cadastrar</Button>
            </div>
        </Modal>
    )
}

export default CreateSerialModal;
