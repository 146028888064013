import { useContext, useEffect, useState } from "react";
import Button from "../../atoms/button";
import { getStores } from "../../stores/functions";
import { assignStoreToUser } from "../functions";
import AuthContext from "../../../context/authProvider";
import ToastContext from "../../../context/toastProvider";

const UserStore = ({ editableUser, handleClose, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [stores, setStores] = useState([]);
    const [store, setStore] = useState("");

    const doGetStores = async () => {
        const data = await getStores(user.token);
        if(data.type === "success"){
            setStores(data.data);
        }
    }

    useEffect(() => {
        doGetStores();
    // eslint-disable-next-line
    }, [])

    const handleSubmit = async () => {
        if(store === ""){
            return null;
        }
        const data = await assignStoreToUser(user.token, editableUser._id, { store: store });
        if(data.type === "success"){
            goodToast("Loja atribuída ao usuário!");
            handleClose();
            refresh();
        }
        if(data.type === "error"){
            badToast("Nenhuma alteração feita");
        }
    }

    return (
        <div className="m-10 mb-2">
            <div className="grid grid-cols-2 w-fit m-auto gap-y-3">
                <label htmlFor="actual">Loja atual: </label>
                <span>{editableUser.store?.name || "Nenhuma"}</span>
                <label htmlFor="store">Atribuir: </label>
                <select name="Lojas" id="store" className="text-black text-center px-2 py-1" onChange={e => setStore(e.target.value)}>
                    <option value="">Selecionar</option>
                    {
                        stores.map(s => (
                            <option key={s._id} value={s._id}>{s.name}</option>
                        ))
                    }
                </select>
            </div>
            <div className="flex w-fit m-auto gap-3 mt-8">
                <Button classes="bg-red-500 hover:bg-red-800" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 disabled:bg-gray-700" click={handleSubmit} disabled={store === ""}>Atribuir</Button>
            </div>
        </div>
    )
}

export default UserStore;
