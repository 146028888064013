import { useState } from "react";
import Modal from "../../utils/modal";
import UserInfo from "./userInfo";
import UserPass from "./userPass";
import UserStore from "./userStore";
import UserAddress from "./userAddress";

const UserModal = ({ setEdit, editableUser, refresh }) => {

    const [tab, setTab] = useState(0);

    const handleClose = () => {
        setEdit(false);
    }

    const activeTab = "bg-[#d59106] text-black px-2 cursor-pointer";
    const inactiveTab = "border border-[#d59106] px-2 cursor-pointer";
    const disabledTab = "bg-gray-700 text-black px-2";

    return (
        <Modal handleClose={handleClose}>
            <div className="text-left h-8 flex gap-2"  onClick={(e) => e.stopPropagation()}>
                <span className={tab === 0 ? activeTab : inactiveTab} onClick={() => setTab(0)}>
                    Infos
                </span>
                <span className={tab === 1 ? activeTab : inactiveTab} onClick={() => setTab(1)}>
                    Senha
                </span>
                <span className={!editableUser.roles.includes("partner") ? disabledTab : tab === 2 ? activeTab : inactiveTab} onClick={() => editableUser.roles.includes("partner") ? setTab(2) : null}>
                    Loja
                </span>
                <span className={tab === 3 ? activeTab : inactiveTab} onClick={() => setTab(3)}>
                    Endereço
                </span>
            </div>
            {
                tab === 0 ?
                <UserInfo editableUser={editableUser} handleClose={handleClose} refresh={refresh}/>
                : tab === 1 ?
                <UserPass editableUser={editableUser} handleClose={handleClose}/>
                : tab === 2 ?
                <UserStore editableUser={editableUser} handleClose={handleClose} refresh={refresh}/>
                : tab === 3 ?
                <UserAddress editableUser={editableUser}/>
                : null
            }
            
        </Modal>
    )
}

export default UserModal;