import { useState } from "react";
import { createContext } from "react";
import Toast from "../components/atoms/toast";

const ToastContext = createContext({});

export const ToastProvider = ({ children }) => {
    
    const [bToast, setBToast] = useState(false);
    const [gToast, setGToast] = useState(false);
    const [message, setMessage] = useState("");

    const goodToast = (text) => {
        setMessage(text);
        setGToast(true);
    }

    const badToast = (text) => {
        setMessage(text);
        setBToast(true);
    }

    return(
        <ToastContext.Provider value={
            {
                goodToast, badToast
            }
        }>
            {children}
            <Toast toast={gToast} setToast={setGToast} colors="bg-green-600" text={message}/>
            <Toast toast={bToast} setToast={setBToast} colors="bg-red-600" text={message}/>
        </ToastContext.Provider>
    )
}

export default ToastContext;