import { useContext, useState } from "react";
import Button from "../atoms/button";
import { getGradableModels } from "./functions";
import ToastContext from "../../context/toastProvider";
import Loading from "../atoms/loading";
import GradableModelsList from "./gradableModelsList";
import GradableModelModal from "./gradableModelModal/gradableModelModal";
import CreateGradableModelModal from "./createGradableModelModal";

const GradableModels = ({ user }) => {

    const { badToast } = useContext(ToastContext);

    const [gradableModels, setGradableModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [register, setRegister] = useState(false);
    const [editableGradableModel, setEditableGradableModel] = useState({});

    const [searchId, setSearchId] = useState("");
    const [game, setGame] = useState("");
    const [name, setName] = useState("");
    const [numbering, setNumbering] = useState("");

    const searchGradableModels = async () => {
        const data = await getGradableModels(user.token, searchId, game, name, numbering);
        
        setGradableModels(data);
        setLoading(false)
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    const handleSearch = () => {
        setLoading(true);
        searchGradableModels();
    }

    return (
        <main className="text-center">
            <h1>Modelos Avaliáveis</h1>
            <div className="border-b border-t border-[#d59106] p-2">
                    <input className="m-2 text-black" type="text" placeholder="ID do modelo" value={searchId} onChange={e => setSearchId(e.target.value)}/>
                    <input className="m-2 text-black" type="text" placeholder="Jogo" value={game} onChange={e => setGame(e.target.value)}/>
                    <input className="m-2 text-black" type="text" placeholder="Nome" value={name} onChange={e => setName(e.target.value)}/>
                    <input className="m-2 text-black" type="text" placeholder="Numeração" value={numbering} onChange={e => setNumbering(e.target.value)}/>
                    <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline mx-4">{"Pesquisar"}</Button>
                    <Button classes="bg-blue-300 hover:bg-blue-400 inline ml-2" click={() => setRegister(true)}>Cadastrar Modelo</Button>
               
            </div>
            {
                loading ? <Loading classes="m-auto"/>
                : gradableModels?.type === "success" ?
                <GradableModelsList gradableModels={gradableModels.data} setEdit={setEdit} setEditableGradableModel={setEditableGradableModel}/>
                : gradableModels?.type === "error" ?
                <div>
                    <p className="text-center m-8 text-red-600">{gradableModels.message}</p>
                </div>
                : null
            }
            {
                edit ? <GradableModelModal setEdit={setEdit} editableGradableModel={editableGradableModel} refresh={handleSearch}/> : null
            }
            {
                register ? <CreateGradableModelModal setRegister={setRegister} refresh={handleSearch}/> : null
            }
        </main>
    )
}

export default GradableModels;
