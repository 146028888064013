import { useContext, useState } from "react";
import Button from "../atoms/button";
import Loading from "../atoms/loading";
import TransactionsList from "./transactionsList";
import { getTransactions } from "./functions";
import ToastContext from "../../context/toastProvider";
import TransactionModal from "./transactionModal";

const Transactions = ({ user }) => {

    const { badToast } = useContext(ToastContext);

    const [email, setEmail] = useState("");
    const [productId, setProductId] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editableTransaction, setEditableTransaction] = useState({});

    const searchTransactions = async () => {
        const data = await getTransactions(user.token, productId, email);
        
        setTransactions(data);
        setLoading(false)
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    const handleSearch = () => {
        setLoading(true)
        searchTransactions();
    }

    return (
        <main className="text-center">
            <h1>Transações</h1>
            <div className="border-b border-t border-[#d59106] p-2">
                <input className="m-2 text-black" type="text" placeholder="ID do produto" value={productId} onChange={e => setProductId(e.target.value)}/>
                <input className="m-2 text-black" type="text" placeholder="Email do usuário" value={email} onChange={e => setEmail(e.target.value)}/>
                <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline ml-4">{email === "" ? "Pesquisar todos" : "Pesquisar"}</Button>
            </div>
            {
                loading ? <Loading classes="m-auto"/>
                : transactions?.type === "success" ?
                <TransactionsList transactions={transactions.data} setEdit={setEdit} setEditableTransaction={setEditableTransaction}/>
                : transactions?.type === "error" ?
                <div>
                    <p className="text-center m-8 text-red-600">{transactions.message}</p>
                </div>
                : null
            }
            {
                edit ? <TransactionModal setEdit={setEdit} editableTransaction={editableTransaction} refresh={handleSearch}/> : null
            }
        </main>
    )
}

export default Transactions;