import { useContext, useEffect, useState } from "react";
import ProductsList from "./productsList";
import Loading from "../atoms/loading";
import { getProducts } from "./functions";
import Button from "../atoms/button";
import ProductModal from "./productModal/productModal";
import ToastContext from "../../context/toastProvider";
import { statusList, statusObject } from "../utils/allProductStatus";
import { STORES_URL } from "../../context/paths";
import CreateProductModal from "./createProductModal";

const Products = ({ user }) => {

    const { badToast } = useContext(ToastContext);

    const [searchEmail, setSearchEmail] = useState("");
    const [searchId, setSearchId] = useState("");
    const [searchStatus, setSearchStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [edit, setEdit] = useState(false);
    const [editableProduct, setEditableProduct] = useState(null);
    const [delivery, setDelivery] = useState("");
    const [stores, setStores] = useState([]);
    const [store, setStore] = useState("");
    const [createModal, setCreateModal] = useState(false);

    const searchProducts = async () => {
        const data = await getProducts(user.token, searchEmail, searchId, searchStatus, delivery, store);
        setProducts(data);
        setLoading(false)
        if(data.type === "error"){
           badToast("Erro ao buscar produtos!")
        }
    }

    const handleSearch = () => {
        if(delivery !== "presencial"){
            setStore("");
        }
        setLoading(true)
        searchProducts();
    }

    const getStores = async () => {
        const storesOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            }
        }
        try{
            const response = await fetch(STORES_URL, storesOptions);
            const data = await response.json();
            if(data.type === "success"){
                setStores(data.data);
            }
            
        }catch(e){
            console.log("erro");
        }
    }

    useEffect(() => {
        getStores();
    // eslint-disable-next-line
    }, [])


    return (
        <main className="text-center">
            <h1>Pedidos</h1>
            <div className="border-b border-t border-[#d59106] p-2">
                <div className="inline">
                    <input className="m-2 text-black" type="text" placeholder="ID do pedido" value={searchId} onChange={e => setSearchId(e.target.value)}/>
                    <input className="m-2 text-black" type="text" placeholder="Email do usuário" value={searchEmail} onChange={e => setSearchEmail(e.target.value)}/>
                    <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline ml-4">{searchEmail === "" && searchId === "" && searchStatus === "" && store === "" && delivery === "" ? "Pesquisar todos" : "Pesquisar"}</Button>
                </div>
                <Button classes="bg-blue-300 hover:bg-blue-400 inline ml-4" click={() => setCreateModal(true)}>Criar Pedido</Button>
                <div className="mb-2">
                    <label className="m-2">Entrega: 
                        <select className="text-black text-center px-1" value={delivery} onChange={e => setDelivery(e.target.value)}>
                            <option value="">Todas</option>
                            <option value="presencial">Presencial</option>
                            <option value="correios">Correio</option>
                        </select>
                    </label>
                    {
                        delivery === "presencial" ? 
                        <label className="m-2">Loja: 
                            <select className="text-black text-center px-1" value={store} onChange={e => setStore(e.target.value)} disabled={!stores.length}>
                                <option value="">Todas</option>
                                {
                                    stores.map(store => (
                                        <option key={store._id} value={store._id}>{store.name}</option>
                                    ))
                                }
                            </select>
                        </label>
                        : null
                    }
                </div>
                <div className="grid grid-cols-5">
                    <label className="bg-black border border-[#d59106] p-1 mr-1">Todos: <input type="radio" name="status" value={""} onChange={e => setSearchStatus(e.target.value)}/></label>
                    {
                        statusList.map(s => (
                            <label key={s} className="bg-black border border-[#d59106] p-1 mr-1">{statusObject[s]}: <input type="radio" name="status" value={s} onChange={e => setSearchStatus(e.target.value)}/></label>
                        ))
                    }
                    
                </div>
            </div>
            {
                loading ? <Loading classes="m-auto"/>
                : products?.type === "success" ?
                <ProductsList products={products.data} setEdit={setEdit} setEditableProduct={setEditableProduct}/>
                : products?.type === "error" ?
                <div>
                    <p className="text-center m-8 text-red-600">{products.message}</p>
                </div>
                : null
            }
            {
                edit ? <ProductModal setEdit={setEdit} editableProduct={editableProduct} refresh={handleSearch}/> : null
            }
            {
                createModal ? <CreateProductModal user={user} setEdit={setCreateModal} refresh={handleSearch}/> : null
            }
        </main>
    )
}

export default Products;