import { useContext, useState } from "react";
import Button from "../../atoms/button";
import { getSpecialPhrase, updateProductStatus } from "../functions";
import AuthContext from "../../../context/authProvider";
import ToastContext from "../../../context/toastProvider";
import { statusObject } from "../../utils/allProductStatus";

const ProductStatus = ({ editableProduct, handleClose, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [status, setStatus] = useState(editableProduct.status);
    const [notify, setNotify] = useState(false);
    const [phrase, setPhrase] = useState("");
    const [returnTrackingCode, setReturnTrackingCode] = useState(editableProduct.returnTrackingCode || "");

    const handleSubmit = async () => {
        const data = await updateProductStatus(user.token, editableProduct._id, status, notify, statusObject[status], returnTrackingCode);
        if(data.type === "success"){
            goodToast("Status do produto atualizado!");
            handleClose();
            refresh();
        }
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    const doGetSpecialPhrase = async () => {
        const data = await getSpecialPhrase(user.token, editableProduct._id);
        if(data.type === "error"){
            badToast(data.message);
            return null;
        }
        if(data.type === "success"){
            setPhrase(data.data);
        }
    }

    return (
        <div>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableProduct._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            <p className="my-4">Entrega: {editableProduct.delivery}</p>
            <p className="my-4">Status: {statusObject[editableProduct.status]}</p>
            <p className="my-4">({editableProduct.status})</p>
            <div className="">
                <label className="">Atualizar status: </label>
                <select type="select" className="text-black text-center my-4" value={status} onChange={e => setStatus(e.target.value)}>
                    <option value="GENERATED">CRIADO PELO ADMIN</option>
                    <option value="CART">CARRINHO</option>
                    <option value="PENDING">PAGAMENTO PENDENTE</option>
                    <option value="PROCESSED">PAGAMENTO PROCESSADO</option>
                    <option value="APPROVED">PAGAMENTO AUTORIZADO</option>
                    <option value="CANCELED">PAGAMENTO CANCELADO</option>
                    <option value="HOLD">AGUARDANDO MANAFIX</option>
                    <option value="SENT">ENVIADO PARA MANAFIX</option>
                    <option value="QUEUE">RECEBIDO</option>
                    <option value="GRADING">AVALIANDO</option>
                    <option value="GRADED">AVALIADO</option>
                    <option value="AWAITING">AGUARDANDO CLIENTE</option>
                    <option value="RETURNED">ENVIADO PARA CLIENTE</option>
                    <option value="DELIVERED">ENTREGUE</option>
                    <option value="DONE">FINALIZADO</option>
                </select>
            </div>
            {
                editableProduct.status === "RETURNED" || status === "RETURNED" ?
                <div className="flex w-fit m-auto gap-2 mb-4">
                    <label>Código de rastreio</label>
                    <input type="text" className="text-black text-center" value={returnTrackingCode} onChange={e => setReturnTrackingCode(e.target.value)}/>
                </div>
                : null
            }
            <div className="mb-4">
                <label className="mr-2">Notificar usuário: </label>
                <input type="checkbox" value={notify} onChange={() => setNotify(!notify)}/>
            </div>
            <div className="flex flex-col">
                <Button classes="bg-purple-400 hover:bg-purple-600 w-1/3" click={doGetSpecialPhrase} 
                    disabled={["CART", "GENERATED", "PIX", "PROCESSED", "PENDING", "APPROVED", "CANCELED"].includes(editableProduct.status) || phrase !== ""}>
                    Ver Frase Secreta
                </Button>
                <label className="p-2 font-bold text-[#d59106]">{phrase}</label>
            </div>
            <div className="flex w-1/2 mx-auto my-4">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit}>Atualizar</Button>
            </div>
        </div>
    )
}

export default ProductStatus;