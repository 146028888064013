export function formatarData(data) {
    if(!data){
        return null;
    }
    const dateObject = new Date(data);
    // Obtém o dia, mês e ano da data
    const dia = String(dateObject.getDate()).padStart(2, '0');
    const mes = String(dateObject.getMonth() + 1).padStart(2, '0'); // Mês é base 0, por isso adicionamos 1
    const ano = dateObject.getFullYear();

    // Retorna a data formatada
    return `${dia}/${mes}/${ano}`;
}

export function pegarHorario(data){
    if(!data){
        return null;
    }
    const dateObject = new Date(data);
    // Obtém as horas e minutos da data
    const horas = String(dateObject.getHours()).padStart(2, '0');
    const minutos = String(dateObject.getMinutes()).padStart(2, '0');

    // Retorna o horário formatado
    return `${horas}:${minutos}`;    
}

export const isNumeric = (str) => {
    if(str === ""){
        return true;
    }
    return /^\d+$/.test(str);
}

export const cashnator = (value) => {
    if(isNumeric(value) && value !== ""){
        const reais = parseFloat(value)/100;
        return `R$${reais.toFixed(2).toString().replace(".", ",")}`;
    }else if(value === ""){
        return "R$0,00";
    }
}

export const decashnator = (value) => {
    const reais = value.split(",")[0].substring(2);
    const centavos = value.split(",")[1];
    return parseInt(`${reais}${centavos}`);
}
