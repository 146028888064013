import { useState } from "react";
import Button from "../atoms/button";

const CouponsList = ({ coupons, setEdit, setEditableCoupon }) => {

    const [page, setPage] = useState(1);

    const gmsLen = coupons.length;
    const perPage = 100;
    let gmsPages;
    if(gmsLen % perPage === 0){
        gmsPages = Math.floor(gmsLen / perPage) || 1;
    }else{
        gmsPages = Math.floor(gmsLen / perPage)+1 || 1;
    }

    const paginatedCoupons = coupons.slice((page-1)*perPage, page*perPage);

    const handleEdit = (coupon) => {
        setEdit(true);
        setEditableCoupon(coupon);
    }

    const nextPage = () => {
        if(page<gmsPages){
            setPage(page+1);
        }
    }

    const previousPage = () => {
        if(page>1){
            setPage(page-1);
        }
    }

    return (
        <div>
            <table className="text-center m-auto gap-5">
                <thead>
                    <tr>
                        <th className="p-4">Código</th>
                        <th className="p-4">Valor</th>
                        <th className="p-4">Tipo</th>
                        <th className="p-4">Ativo</th>
                        <th className="p-4">Utilizações</th>
                        <th className="p-4"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        paginatedCoupons.map((coupon, idx) => (
                            <tr key={idx}>
                                <td className="p-4">{coupon.code}</td>
                                <td className="p-4">{coupon.value}</td>
                                <td className="p-4">{coupon.kind === "percent" ? "Percentual" : coupon.kind === "amount" ? "Quantia" : ""}</td>
                                <td className="p-4">{coupon.isActive ? "✔" : "X"}</td>
                                <td className="p-4">{coupon.timesUsed}</td>
                                <td className="p-4"><Button classes="bg-blue-500 hover:bg-blue-800" click={() => handleEdit(coupon)}>Atualizar</Button></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className="my-4">
                <label>Página </label>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={previousPage} disabled={page===1}>
                    {"< "}
                </Button>
                <h3 className="inline mx-4">{page}</h3>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={nextPage} disabled={page===gmsPages}>
                    {" >"}
                </Button>
                <h3 className="inline mx-4"> de {gmsPages}</h3>
            </div>
        </div>
    )
}

export default CouponsList;
