import { STORES_URL } from "../../context/paths";

export const getStores = async (token) => {
    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    try{
        const response = await fetch(STORES_URL, searchOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
    
}

export const addStore = async (token, body) => {
    const addOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const addURL = `${STORES_URL}/add`;

    try{
        const response = await fetch(addURL, addOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const updateStore = async (token, storeId, body) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const updateURL = `${STORES_URL}/update/${storeId}`;

    try{
        const response = await fetch(updateURL, updateOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}
