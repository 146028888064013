import { useContext } from "react";
import AuthContext from "../../context/authProvider";
import Button from "../atoms/button";

const YouShallNotPass = ({ navigate }) => {

    const { logout } = useContext(AuthContext);

    const handleLogout = () => {
        logout();
        navigate("/")
    }

    return (
        <main className="bg-black text-[#d59106] h-screen text-center p-40">
            <h1 className="mb-5">Você não vai passar!</h1>
            <Button classes="bg-[#d59106] hover:bg-[#b47c06]" click={handleLogout}>Sair</Button>
        </main>
    )
}

export default YouShallNotPass;